import * as React from "react"
import styled from "@emotion/styled"

export const RBA = styled.a`
  display: inline-flex;
`

function RbaLogo(props) {
  return (
    <RBA href="/">
      <svg
        aria-labelledby="rbalogo"
        id="rba"
        role="img"
        viewBox="0 0 100 39.713"
        style={{ height: "21px" }}
        {...props}
      >
        <title id="rba" lang="en">
          RBA.DEV - Ricardo Aguiar Frontend Dev
        </title>
        <g
          id="prefix__g835"
          transform="matrix(.34913 0 0 .34913 -.01 0)"
          fill="#000"
        >
          <path
            transform="translate(-154.26 -359.46)"
            d="M252.39 403c0-24.05-17.38-43.53-50-43.53h-48.1v113.74h20.36v-26.86h25.81a62.42 62.42 0 0010.18-.88l19.83 27.74h23.7L229 439.86A40.37 40.37 0 00252.39 403zm-49.15 25.8h-28.62v-52h28.79c16.15 0 27.56 11.58 27.56 26.15.03 14.26-11.41 25.85-27.73 25.85z"
            opacity={1}
          />
          <path
            transform="translate(-154.26 -359.46)"
            d="M329.27 408.61c6.32-3.16 11.94-11.41 11.94-20.36 0-20-15.27-28.79-34.06-28.79h-53v113.75h64.77l25.25-56a28.24 28.24 0 00-14.9-8.6zM274.51 377h30.71c9.83 0 14.75 5.27 14.75 13s-5.09 12.47-13.87 12.47h-31.59zm32.82 78.64h-32.82v-35.8h34.75c13 0 21.42 6.32 21.42 18.26 0 11.76-9.13 17.55-23.35 17.55z"
            opacity={1}
          />
          <path
            transform="translate(-154.26 -359.46)"
            d="M389.48 359.46h-19.31l-26 57.73a30.22 30.22 0 017.76 20.56c0 20-13 31.71-32.66 34.69l-.35.77h20.72l8.95-19.66h62.49l8.92 19.66h20.72zM355.6 437.4l13-29.49c5.09-11.41 11.24-26.51 11.24-26.51s6.32 15.27 11.23 26.51l13 29.49z"
            opacity={1}
          />
        </g>
      </svg>
    </RBA>
  )
}

const MemoRbaLogo = React.memo(RbaLogo)
export default MemoRbaLogo
